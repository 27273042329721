import React from "react";
import "./partenariat.css";
import NavBar from "../utils/navbar/navbar.jsx"

// Image
import p1 from './img/01_1920x1080.png';
import p2 from './img/02_1920x989.png';
import p3 from './img/03_1920x989.png';
import p4 from './img/04_1920x820.png';
import p5 from './img/05_1920x989.png';
import p6 from './img/06_1920x989.png';
import p7 from './img/07_1920x989.png';
import p8 from './img/08_1920x820.png';
import p9 from './img/09_1920x989.png';
import p10 from './img/10_1920x820.png';
import p11 from './img/11_LeCul.png';

function Partenariat( {user} ) {
    return (
        <div className="partenaire-page">
           
            <NavBar user={user}/>

            <main>
                <section className="partenaires">
                    <span className="visu">
                        <img src={p1} alt="16:9"/>
                    </span>
                    {/* <span className="visu">
                        <img src={p2} alt="16:9"/>
                    </span> */}
                    <span className="visu">
                        <img src={p3} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p4} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p5} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p6} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p7} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p8} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p9} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p10} alt="16:9"/>
                    </span>
                    <span className="visu">
                        <img src={p11} alt="16:9"/>
                    </span>
                </section>
            </main>
        </div>
    );
}

export default Partenariat;