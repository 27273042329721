import React from "react";
import NavBar from "../utils/navbar/navbar.jsx"
import "./calendrier.css";
import 'react-calendar/dist/Calendar.css';
import MyCalendar from "./MyCalendar.jsx";

export function Calendrier( {user} ) {
    return (
        <div className="calendar-page">
            
            <NavBar user={user}/>
           
            <main>
                
                <MyCalendar/>
                
            </main>
        </div>
    );
}

export default Calendrier;
