import React from "react";
import { Link } from "react-router-dom";
import "./contact.css";
import NavBar from "../utils/navbar/navbar.jsx"
import MyMap from "./maps.jsx";


// images

import TikTokIcon from "./img/Tiktok150.jpg";
import InstagramIcon from "./img/InstaRemoveBG150.png";
import MailIcon from "./img/Outlook.png";

export function Contact( {user} ) {
    return (
        <div className="contact-page">
            
            <NavBar user={user} className="topbar"/>
            
            <main>
                <div className="grid">
                    <div className="social">
                        <span className="pseudo">@BDETaurosTokTok</span>
                        <img src={TikTokIcon} alt="TikTok"/>
                        <button onClick={()=>window.open("https://www.tiktok.com/@bdecesitoulouse", '_blank')}className="follow">Follow</button>
                    </div>
                    <div className="social">
                        <span className="pseudo">@bdecesitoulouse</span>
                        <img src={InstagramIcon} alt="TikTok"/>
                        <button onClick={()=>window.open("https://www.instagram.com/bdecesitoulouse/?hl=fr", '_blank')} className="follow">Follow</button>
                    </div>
                    <div className="social">
                        <span className="pseudo">bde-toulouse@viacesi.fr</span>
                        <img src={MailIcon} alt="TikTok"/>
                        <button onClick={()=>window.open("mailto:bde-toulouse@viacesi.fr?subject=Lien depuis le site web")} className="follow">Send Mail</button>
                    </div>
                </div>
                <div className="carte">
                    <MyMap/>
                </div>
                <div className="endtext">
                    16 Rue Magellan, 31670 Labège Toulouse
                </div>
            </main>

        </div>
    );
}

export default Contact;