import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import './resetpassword.css';
import { Link } from 'react-router-dom';

// Images
import LogoTauros from './img/Logo150.png';

function ResetPassword() {

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);

  const oobCode = new URL(window.location.href).searchParams.get('oobCode'); // Extraire le code

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas !");
      return;
    }

    const auth = getAuth();
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccess(true);
    } catch (error) {
      console.error("Erreur lors de la réinitialisation du mot de passe:", error);
    }
  };

  return (

    <main className='reset-page'>
      <Link to="/home" className='link'>
        <img src={LogoTauros} alt="logo"/>
      </Link>

      <form onSubmit={handleSubmit}>

        <label>Nouveau mot de passe :</label>

        <div className="newpassword">
          <input type={showPassword ? "text" : "password"} value={newPassword} onChange={e => setNewPassword(e.target.value)} required />
          <button type="button" onClick={() => setShowPassword(!showPassword)}>{showPassword ? "Cacher" : "Montrer"}</button>
        </div>

        <label>Confirmez le mot de passe :</label>
        <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
        <button type="submit">Réinitialiser le mot de passe</button>
        {success && <p>Mot de passe réinitialisé avec succès!</p>}

      </form>

    </main>
  );
}

export default ResetPassword;