import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDi9pRO997Jk4FaO4xjvfZkStZGs1pdryc",
  authDomain: "bdetauros.firebaseapp.com",
  projectId: "bdetauros",
  storageBucket: "bdetauros.appspot.com",
  messagingSenderId: "784798013374",
  appId: "1:784798013374:web:bfed4ab13b9e82b30a8bef"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Le mode de persistance a été défini");
  })
  .catch((error) => {
    console.error("Une erreur s'est produite lors de la définition de la persistance", error);
  });

export { auth };