import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from '../firebaseConfig'
// Pages
import Welcome from '../api/start/welcome.jsx';
import Home from '../api/home/home.jsx';
import Calendrier from '../api/calendrier/calendrier.jsx';
import Club from '../api/clubcesi/club.jsx';
import Album from '../api/album/album.jsx';
import Partenariat from '../api/partenariat/partenariat.jsx';
import Shop from '../api/shop/shop.jsx';
import Trustedpeople from '../api/trustedpeople/trustedpeople.jsx';
import Contact from '../api/contact/contact.jsx';
import Login from '../api/login/Login';
import PrivateRoute from './PrivateRoute.jsx';
import MemberZone from '../api/memberzone/memberzone.jsx';
import ResetPassword from '../api/resetpassword/resetpassword.jsx';
import Event from '../api/event/event.jsx';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Ajoute un observateur d'état pour suivre l'état de connexion de l'utilisateur
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Nettoie l'observateur d'état lorsque le composant est démonté
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} exact />
        <Route path="/memberzone" element={<PrivateRoute user={user}><MemberZone /></PrivateRoute>} />
        <Route path="/event" element={<Event />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword/>} />
        <Route path="/home" element={<Home user={user}/>} />
        <Route path="/calendar" element={<Calendrier user={user}/>} />
        <Route path="/club" element={<Club user={user}/>} />
        <Route path="/album" element={<Album user={user}/>} />
        <Route path="/partenariat" element={<Partenariat user={user}/>} />
        <Route path="/shop" element={<Shop user={user}/>} />
        <Route path="/trustedpeople" element={<Trustedpeople user={user}/>} />
        <Route path="/contact" element={<Contact user={user}/>} />
      </Routes>
    </Router>
  );
}

export default App;
