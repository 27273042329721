import React from 'react';
import { Link } from 'react-router-dom';  
import './welcome.css';

export function Welcome() {
    return (
        <div className="welcome">
            <Link to="/home">
                <button className="welcome-button">Entrez chez les tauros</button>
            </Link>
        </div>
    );
}

export default Welcome;
