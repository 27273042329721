import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageCarousel from "./carousel.jsx";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./home.css";

// Functions

import NavBar from "../utils/navbar/navbar.jsx"
// import { listFilesInFolder } from './GoogleDriveApi.js';

// Images

import Group from "./img/TaurosGroup.jpg"


export function Home( {user} ) {
    const [files, setFiles] = useState([]);
    const BanniereImg = `${process.env.PUBLIC_URL}/asset/Banniere_2023.png`;

    // useEffect(() => {
    //     async function fetchFiles() {
    //         const filesList = await listFilesInFolder();
    //         setFiles(filesList);
    //     }
    //     fetchFiles();
    // }, []);
    

    return (
        <div className="home-page">
            
            <NavBar user={user}/>
            
            <main>
                
                <div className="slogan">
                    <h2>"CESI le taureau par les cornes"</h2>
                </div>
                <div className="banniere">
                    <img src={BanniereImg} alt="Banniere_2022_2023" />
                </div>
                <article className="actualite">

                    <h1>Actualités</h1>

                    <ul>
                        {files.map(file => <li key={file.id}>{file.name}</li>)}
                    </ul>

                    <Link>
                        <button>En savoir plus ...</button>
                    </Link>
                </article>

                <article className="presentation">
                    
                    <h1>Bienvenue sur le site du BDE ! 🐂</h1>
                    
                    <div className="quote">

                        <p className="q1">Tu retrouveras ici toutes les actualités relatives aux évènements et aux partenariats organisés par le BDE Tauros du campus de CESI Toulouse.</p>

                        <h2>Qui Sommes-Nous ?</h2>
                        <p className="q2">👉Nous sommes 20 étudiants issus de 4 promotions différentes, mêlant rire et sérieux, nous avons à cœur d&apos;organiser les meilleurs évènements pour les étudiants du CESI Toulouse.</p>

                        <p className="q2">Pour mieux te régaler toute l&apos;année, voici nos 3 types d&apos;events (il y en a forcément un pour toi) :</p>
                        <ul>
                            <li>Evènements sociétaux (Clean Walk notamment)</li>
                            <li>Evènements chill dans le CESI (Cesi ta pizza)</li>
                            <li>Evènements festifs (WEI, WES)</li>
                        </ul>
                        <p>🎁Et bien sûr, pleins d&apos;autres surprises !</p>

                        <h2>La force de notre BDE :</h2>

                        <p>🤝PARTENARIATS</p>
                        <p>Tous les avantages d&apos;un étudiant CESI concentré dans une page - (lien vers les partenariats si possible)</p>

                        <p>👨‍🎓 PARRAINAGE 👩‍🎓</p>
                        <p>Nouveau au CESI ? Le BDE t&apos;accompagne en t&apos;affectant un parrain (ou une marraine) pour toute ta première année.</p>

                        <p>En bref, le BDE Tauros c&apos;est le Bureau des Elèves que tu vas adorer !</p>

                        <p>Césien, Césienne, partenaire ou simple curieux, toute l&apos;équipe te souhaite une agréable visite sur son site</p>

                        <p>L&apos;équipe de choc des Tauros !</p>
                        <p>💛</p>

                    </div>
                    <div className="group-tauros">
                        <img src={Group} alt="Groupe Tauros"/>
                    </div>
                </article>
            </main>
        </div>
    );
}

export default Home;