import React, { useState } from 'react';
import { auth } from '../../firebaseConfig';
import { Link, Navigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import './Login.css';

// Images
import Tauros from './img/TaurosBg.png';
import LogoTauros from './img/Logo_officiel.png';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, username, password)
      .then(() => {
        console.log("Authentification réussie");
        setIsLoggedIn(true);
      })
      .catch(error => {
        console.error("Erreur d'authentification", error);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/memberzone" replace />;
  }

  return (
    <main className="login-page">
      <div className="side">
        <img src={Tauros} alt="Tauros" />
      </div>
      <div className="login-container">
        <Link to="/home" className="link-logo">
          <img src={LogoTauros} alt="Logo Tauros" className='logo'/>
        </Link>
        <form onSubmit={handleSubmit}>
          <label>Identifiant :</label>
          <input type="text" value={username} onChange={e => setUsername(e.target.value)} required />
          <label>Mot de passe :</label>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
          <button type="submit">Se connecter</button>
        </form>
        <Link to="/resetpassword" className='forget-password'>
          <span>Mot de passe oublié ?</span>
        </Link>
      </div>
      <div className="side">
        <img src={Tauros} alt="Tauros" className='mirror'/>
      </div>
    </main>
  );
}

export default Login;
