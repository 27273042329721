import React, { useEffect, useRef } from 'react';
import './maps.css';

function MyMap() {
  const mapRef = useRef(null);

  useEffect(() => {
    // Vérifie si Google Maps est déjà chargé
    if (window.google && window.google.maps) {
      initMap();
      return;
    }

    // Charge le script Google Maps
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD0IlW0B7mwul0QW4dNHs58DFxYdsTkyWw&v=weekly';
    script.async = true;
    script.defer = true;
    script.onload = initMap; // Utilise onload au lieu d'un callback dans l'URL
    document.head.appendChild(script);
  }, []);

  const initMap = () => {
    const { Map, Marker } = window.google.maps;

    const location = { lat: 43.54834893738126, lng: 1.5028395847836562 };

    const map = new Map(mapRef.current, {
      center: location,
      zoom: 17,
    });

    new Marker({
      position: location,
      map: map,
    });
  };

  return (
    <div className='map-container'>
      <div id="map" ref={mapRef}></div>
    </div>
  );
}

export default MyMap;
