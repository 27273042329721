import React from 'react';
import "./popup.css"
import moment from 'moment';

function PopUp({ event, onClose }) {
  if (!event) return null;
  console.log(event)

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{event.title}</h2>
        <p>Date : {moment(event.start).format("MM/DD/YYYY HH:mm")}</p>
        <p>Lieu : <a href={event.place}>{event.place}</a></p>
        {event.shop === "" ? 
          (<p>Billeterie : Aucune</p>)
          :
          (<p>Billetterie : <a href={event.shop}>Lien</a></p>)
        }
        <p>{event.description}</p>
      </div>
    </div>
  );
}

export default PopUp;
