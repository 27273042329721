import React from "react";
import NavBar from "../utils/navbar/navbar.jsx"
import "./album.css";

// Images
import EventMerguez from "./img/Merguez200.png";
import CampagneAlbum from "./img/CampagneAlbum.png";
import InteAlbum from "./img/InteAlbum.png";
import WeiAlbum from "./img/WeiAlbum.png";
import { Link } from "react-router-dom";

export function Album({user}) {
    return (
        <div className="album-page">
            
            <NavBar user={user}/>
            
            <main>
                <div className="grid">
                    <div className="album">
                        <a href="https://viacesifr-my.sharepoint.com/:f:/g/personal/kilian_levu_viacesi_fr/EmFTf4_egkxJuCqoDL_mcMsBWfXO7k-VUU-LF0Mkqfv9jw?e=0llj3K" target="_blank" >
                            <img src={CampagneAlbum} alt="Event Merguez" className="logo"/>
                        </a>
                        <span className="text">Campagne</span>
                    </div>
                    <div className="album">
                        <a href="https://viacesifr-my.sharepoint.com/:f:/g/personal/kilian_levu_viacesi_fr/Est2Fmyd10pDnnZU0szbuIUBO8wc6LpW3H0lCVItCj6IJw?e=8nX1bV" target="_blank" >
                            <img src={WeiAlbum} alt="Event Merguez" className="logo"/>
                        </a>
                        <span className="text">WEI</span>
                    </div>
                    <div className="album">
                        <a href="https://viacesifr-my.sharepoint.com/:f:/g/personal/kilian_levu_viacesi_fr/EhUuTio36BJFhKQjZnbjrUkBN4NtSI8c5csv4vmlLW4uFg?e=a2OOBQ" target="_blank" >
                            <img src={InteAlbum} alt="Event Merguez" className="logo"/>
                        </a>                        
                        <span className="text">Journée d'intégration</span>
                    </div>
                    <div className="album">
                        <a href="https://viacesifr-my.sharepoint.com/:f:/g/personal/kilian_levu_viacesi_fr/Eht4tH0jEVdKp7lSkZN6LlEBkutZiLX15rVvd1IiTJitLg?e=NrvA4r" target="_blank" >
                            <img src={EventMerguez} alt="Event Merguez" className="logo"/>
                        </a>
                        <span className="text">CESI Ta Merguez</span>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Album;