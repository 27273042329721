import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./memberzone.css";

// Images
import LogoTauros from './img/Logo150.png';

function MemberZone({ user }) {

    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    return (
        <main className="member-page">
            <section className={`barside ${isSidebarOpen ? 'open' : 'closed'}`}>
                <Link to="/home">
                    <img src={LogoTauros} alt="Logo Tauros" className='logo'/>
                </Link>
                <span className="title">Espace Membre</span>
                <div className="links">
                    <Link to="/memberzone" className="link">Accueil</Link>
                    <Link to="/memberzone/controlpage" className="link">Modifier une page</Link>
                    <Link to="/memberzone/documents" className="link">Documents</Link>
                </div>    
            </section>
            <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="toggle-button">
                {isSidebarOpen ? "Fermer" : "Ouvrir"}
            </button>
        </main>
    );
}

export default MemberZone;
