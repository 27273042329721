import React, { useEffect, useState } from "react";
import "./trustedpeople.css";
import NavBar from "../utils/navbar/navbar.jsx"

function Member({ member }) {
    const imagePath = `${process.env.PUBLIC_URL}/asset/member/${member.name}.png`;
    return (
      <div className="member">
        <img src={imagePath} alt={member.name} />
        <span className="media">
          <a href={`https://www.instagram.com/${member.instaName}`} target="_blank" rel="noopener noreferrer">@{member.instaName}</a>
        </span>
        <span className="quote">{member.quote}</span>
      </div>
    );
}

export function Trustedpeople( {user} ) {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [members, setMembers] = useState([]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener('resize', handleResize);
  
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, 
    []);

    useEffect(() => {
        const fetchMember = async () => {
            const data = require('./members.json');
            console.log("Données chargées :", data);
            const loadedMembers = data.map(members => ({
                name: members.name,
                role: members.role,
                instaName: members.instaName,
                quote: members.quote,
            }));
            setMembers(loadedMembers);
        };
        fetchMember();
    }, []);

    return (
        <div className="trustedpeople-page">
            
            <NavBar user={user}/>
            
            <main>
                <section className="members">
                    {members.map((member, index) => (
                        <Member key={index} member={member} />
                    ))}
                </section>

                {isMobile ? (
                    <div className="color-description">
                        <div className="color-title">
                            Code Couleur :
                        </div>
                    
                        <div className="row-color">
        
                            <div className="color-description">
                                <span className="color" style={{color: `#FFFF4C`}}>Bureau Restreint</span>
                                <span className="color" style={{color: '#008040'}}>Partenariats</span>
                                <span className="color" style={{color: '#E1306C'}}>Communication</span>
                                <span className="color" style={{color: '#0080FF'}}>Evenementiel</span>
                            </div>
        
                            <div className="color-description">
                                <span className="color" style={{color: 'red'}}>Animation</span>
                                <span className="color" style={{color: 'pink'}}>Associations</span>
                                <span className="color" style={{color: 'purple'}}>Support gestion</span>
                                <span className="color" style={{color: '#A5682A'}}>Actifs</span>
                            </div>

                        </div>
                    </div>
                    ) : (
                        <div className="color-description">
                            
                            <div className="color-title">Code couleur :</div>
                            <div className="row-color">
                                <div className="column-color">
                                    <span className="color" style={{color: `#FFFF4C`}}>Bureau Restreint</span>
                                    <span className="color" style={{color: '#008040'}}>Partenariats</span>
                                </div>
                                <div className="column-color">
                                    <span className="color" style={{color: '#E1306C'}}>Communication</span>
                                    <span className="color" style={{color: '#0080FF'}}>Evenementiel</span>
                                </div>
                                <div className="column-color">
                                    <span className="color" style={{color: 'red'}}>Animation</span>
                                    <span className="color" style={{color: 'pink'}}>Associations</span>
                                </div>
                                <div className="column-color">
                                    <span className="color" style={{color: 'purple'}}>Support gestion</span>
                                    <span className="color" style={{color: '#A5682A'}}>Actifs</span>
                                </div>
                            </div>

                        </div>
                )}
                


            </main>
        </div>
    );
}

export default Trustedpeople;
