import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

// images 

import Logo from "./img/Logo_officiel.png";
import IconeMenu from "./img/IconeMenu25.png";
import Arrow from "./img/Arrow25.png";

function NavBar({ user }) {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const linkToMemberZone = user ? "/memberzone" : "/login";
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  
  return (
    <div className="topbar">
      <header>
        {isMobile ? (
            <span className="header-logo">
              <Link to="/home">
                <img src={Logo} alt="Logo" className="logo"/>
              </Link>
            </span>
        ) : (
          <>
            <span className="header-logo">
              <Link to="/home"><img src={Logo} alt="Logo" className="logo"/></Link>
            </span>
            <span className="header-link">
              <h2><Link to={linkToMemberZone}>Espace membre</Link></h2>
            </span>
          </>
        )}
      </header>
          <nav className={`navbar ${isSidebarOpen ? 'open' : 'closed'}`}>
            {isMobile ? (
              <>
                <Link to={linkToMemberZone} className="link">Espace membre</Link>
              </>
            ) : (
              <>
                <Link to="/home" className="link">Accueil</Link>
              </>
            )}
            <Link to="/calendar" className="link">Calendrier</Link>
            <Link to="/club" className="link">Clubs CESI</Link>
            <Link to="/album" className="link">Albums</Link>
            <Link to="/partenariat" className="link">Partenariats</Link>
            <Link to="/shop" className="link">Boutique</Link>
            <Link to="/trustedpeople" className="link">Trusted People</Link>
            <Link to="/contact" className="link">Contact</Link>
          </nav>
          {isMobile ? (
            <div className="nav-button-container">
              <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="nav-button">
                {isSidebarOpen ? 
                  <img src={Arrow} alt="IconeMenu" className="icone-menu"/>
                : 
                  <img src={IconeMenu} alt="IconeMenu" className="icone-menu"/>
                }
              </button>
            </div>
          ) : (
            <></>
        )}
    </div>
  );
}
  
export default NavBar;