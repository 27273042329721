import { Carousel } from 'react-responsive-carousel';
import Banniere_2023 from './img/Banniere_2023.png';

export function ImageCarousel() {
    return (
        <Carousel 
        autoPlay 
        infiniteLoop
        showIndicators={false} 
        showStatus={false} 
        showThumbs={false}
        >
            <div>
                <img src={Banniere_2023} alt="First slide"/>
                <p className="legend">Légende de l'image 1</p>
            </div>
            {/* <div>
                <img src={Dalle2} alt="Second slide"/>
                <p className="legend">Légende de l'image 2</p>
            </div>
            <div>
                <img src={Dalle3} alt="Third slide"/>
                <p className="legend">Légende de l'image 3</p>
            </div> */}
        </Carousel>
    );
}

export default ImageCarousel;