import React from "react";
import NavBar from "../utils/navbar/navbar";

function Event() {
    return (
        <>
            <NavBar/>
        </>
    );
}

export default Event;