import React from "react";
import { Link } from "react-router-dom";
import "./shop.css";
import NavBar from "../utils/navbar/navbar.jsx"
import CarouselShop from "./carousel.jsx";
// Image
import Smile from './img/Smile.jpg';
import img1 from './img/Insta150.jpg'
import img2 from './img/Tiktok150.jpg'
import WIP from './img/WIP.jpg'

const tabimg = [
    './img/Smile.jpg',
    {Smile},
]

export function Shop( {user} ) {
    return (
        <div className="shop-page">
            
            <NavBar user={user}/>

            <img src={WIP} className="wip"></img>
            
            {/* <CarouselShop images={img1}/>
            
            <main>
                <header>
                    Shop
                </header>

                <div className="product-lign">
                    {products.map((product, index) => (
                    <div className="item" key={index}>
                        <span className="title">{product.title}</span>
                        <CarouselShop images={img1}/>
                        <span className="description">{product.description}</span>
                        <span className="price">{product.price}</span>
                    </div>
                    ))}
                </div>
            </main> */}
        </div>
    );
}

const products = [
    {
        "id": 1,
        "title": "Un Sourire",
        "price": 0.99,
        "description": "Un sourire pour la journée et plus encore...",
        "image": img1,
    },
    {
        "id": 2,
        "title": "Un Rire",
        "price": 1.99,
        "description": "Un rire pour alléger votre journée...",
        "image": Smile,
    },
    {
        "id": 3,
        "title": "Une Joie",
        "price": 2.99,
        "description": "Une joie pour illuminer votre semaine...",
        "image": Smile,
    }
]

export default Shop;


  