import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../utils/navbar/navbar.jsx"
import "./club.css";
import MyCalendar from "../calendrier/MyCalendar.jsx";

// image
import Sonergy from './img/Soenergy_bg.png';
import Blackwolves from './img/Blackwolves.png';
import Astromonkey from './img/AstroMonkey.png';

export function Club( {user} ) {
    return (
        <div className="club-page">

            <NavBar user={user}/>

            <main>
                <section className="grid">
                    
                    <div className="club">
                        <h2>Sonergy</h2>
                        <a className="link">
                            <img src={Sonergy} alt="Sonergy" className="logo"/>
                        </a>
                    </div>

                    <div className="club"> 
                        <h2>Blackwolves</h2>
                        <a href="https://linktr.ee/bds_cesi_blackwolves" className="link"> 
                            <img src={Blackwolves} alt="Blackwolves" className="logo"/>
                        </a>
                    </div>

                    <div className="club">
                        <h2>Astro'Monkey</h2>
                        <a href="https://www.astromonkeys.fr/" className="link"> 
                            <img src={Astromonkey} alt="Astromonkey" className="logo"/>  
                        </a>
                    </div>

                </section>
            </main>
        </div>
    );
}

export default Club;