import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './MyCalendar.css';
import PopUp from './popup.jsx';  // Importez le composant modal

/* ================ Image ================ */
import ImgBg1 from "./img/CalendarCellBg1.png";
import ImgBg2 from "./img/CalendarCellBg2.png";
import ImgBg3 from "./img/CalendarCellBg3.png";
import ImgBg4 from "./img/CalendarCellBg4.png";
import ImgBg5 from "./img/CalendarCellBg5.png";
import ImgBg6 from "./img/CalendarCellBg6.png";
import ImgBg7 from "./img/CalendarCellBg7.png";
import ImgBg8 from "./img/CalendarCellBg8.png";
import ImgBg9 from "./img/CalendarCellBg9.png";
import ImgBg10 from "./img/CalendarCellBg10.png";
import ImgBg11 from "./img/CalendarCellBg11.png";
import ImgBg12 from "./img/CalendarCellBg12.png";
import ImgBg13 from "./img/CalendarCellBg13.png";
import ImgBg14 from "./img/CalendarCellBg14.png";
import ImgBg15 from "./img/CalendarCellBg15.png";
import ImgBg16 from "./img/CalendarCellBg16.png";
import ImgBg17 from "./img/CalendarCellBg17.png";
import ImgBg18 from "./img/CalendarCellBg18.png";
/* ======================================== */

moment.locale('fr');
const localizer = momentLocalizer(moment);

function MyCalendar() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dateImageMap, setDateImageMap] = useState({});
  const imgBackground = [ImgBg1, ImgBg2, ImgBg3, ImgBg4, ImgBg5, ImgBg6, ImgBg7, ImgBg8, ImgBg9, ImgBg10, ImgBg11, ImgBg12, ImgBg13, ImgBg14, ImgBg15, ImgBg16, ImgBg17, ImgBg18];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  /* =========================== */
  /* Defini la taille de l'écran */
  useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 600);
      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, 
  []);
  /* =========================== */

  /* ======================================= */
  /* Choisi une image random pour background */
  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * imgBackground.length);
    return imgBackground[randomIndex];
  };
  /* ======================================= */

  const dayStyleGetter = (date) => {
    let dateString = moment(date).format('YYYY-MM-DD');
    let imageForDate = dateImageMap[dateString];

    if (!imageForDate && events.some(event => moment(date).isBetween(moment(event.start).startOf('day'), moment(event.end).endOf('day'), null, '[]'))) {
      imageForDate = getRandomImage();
      setDateImageMap({ ...dateImageMap, [dateString]: imageForDate });
    }

    return {
      style: imageForDate ? { backgroundImage: `url(${imageForDate})`, backgroundSize: 'cover' } : {}
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = require('./events.json');
      const loadedEvents = data.map(event => ({
        title: event.title,
        start: moment(event.date, "DD/MM/YY/HH:mm").toDate(),
        end: moment(event.date, "DD/MM/YY/HH:mm").toDate(),
        place: event.place,
        shop: event.shop
      }));
      setEvents(loadedEvents);
    };
    fetchData();
  }, []);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  const DayNumber = ({ date }) => {
    const isEventPresent = events.some(event => 
      moment(date).isBetween(moment(event.start).startOf('day'), moment(event.end).endOf('day'), null, '[]')
    );
  
    const dayClass = isEventPresent ? 'event-day' : '';
  
    return <span className={dayClass}>{moment(date).format('D')}</span>;
  };


  return (
    <div className="calendar">
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        views={["month", "agenda"]}
        events={events}
        onSelectEvent={handleSelectEvent}
        dayPropGetter={dayStyleGetter}
        components={{
          week: {
            dateHeader: DayNumber,
          }
        }}
        messages={{
          next: 'Suivant',
          previous: 'Précédent',
          today: "Aujourd'hui",
          month: 'Mois',
          week: 'Semaine',
          day: 'Jour',
          agenda: 'Agenda',
          date: 'Date',
          time: 'Heure',
          event: 'Événement',
        }}
      />
      <PopUp
        event={selectedEvent}
        onClose={handleCloseModal}
      />
    </div>
  );
}

export default MyCalendar;

